<template>
    <transition>
        <form>
            <input type="hidden" id="reCommConId" name="reCommConId" value="<%=rcid%>" />
            <input type="hidden" id="reCnclType" name="reCnclType" value="<%=rcancel%>" />
            <div>
            <table  width=100% border="1" cellspacing="0" cellpadding="0" align="center" >
            <tr>
                <th><b>항목명</b></th>
                <td style="text-align:center;"><b>결과</b></td>
            </tr>
            <tr>
                <th>승인구분</th>
                <td><span id=authyn name=authyn></span></td>
            </tr>
            <tr>
                <th>거래번호</th>
                <td><span id=trno name=trno></span></td>
            </tr>
            <tr>
                <th>거래일자</th>
                <td><span id=trddt name=trddt>&nbsp;:&nbsp;<span id=trdtm name=trdtm></span></span></td>
            </tr>
            <tr>
                <th>승인번호</th>
                <td><span id=authno name=authno></span></td>
            </tr>
            <tr>
                <th>주문번호</th>
                <td><span id=ordno name=ordno></span></td>
            </tr>
            <tr>
                <th>금액</th>
                <td><span id=amt name=amt></span></td>
            </tr>
            <tr>
                <th>메세지1</th>
                <td><span id=msg1 name=msg1></span></td>
            </tr>
            <tr>
                <th>메세지2</th>
                <td><span id=msg2 name=msg2></span></td>
            </tr>
            </table>
            </div>
            <div class="btnDiv">
                <button @#onClick="ksentOpen()" class="viewButton" style="margin-left:4px;">전표보기</button>
            </div>
        </form>
    </transition>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        ksentOpen() {
            window.open("https://pgims.ksnet.co.kr/pg_infoc/src/bill/new_credit_view.jsp?tr_no="+$("#trno").text(), "전표보기");
        },
        closeView() {
            window.open("", "_self").close();
        }

    }
}
</script>

<style>

</style>