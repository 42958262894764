var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', [_c('form', [_c('input', {
    attrs: {
      "type": "hidden",
      "id": "reCommConId",
      "name": "reCommConId",
      "value": "<%=rcid%>"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "id": "reCnclType",
      "name": "reCnclType",
      "value": "<%=rcancel%>"
    }
  }), _c('div', [_c('table', {
    attrs: {
      "width": "100%",
      "border": "1",
      "cellspacing": "0",
      "cellpadding": "0",
      "align": "center"
    }
  }, [_c('tr', [_c('th', [_c('b', [_vm._v("항목명")])]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('b', [_vm._v("결과")])])]), _c('tr', [_c('th', [_vm._v("승인구분")]), _c('td', [_c('span', {
    attrs: {
      "id": "authyn",
      "name": "authyn"
    }
  })])]), _c('tr', [_c('th', [_vm._v("거래번호")]), _c('td', [_c('span', {
    attrs: {
      "id": "trno",
      "name": "trno"
    }
  })])]), _c('tr', [_c('th', [_vm._v("거래일자")]), _c('td', [_c('span', {
    attrs: {
      "id": "trddt",
      "name": "trddt"
    }
  }, [_vm._v(" : "), _c('span', {
    attrs: {
      "id": "trdtm",
      "name": "trdtm"
    }
  })])])]), _c('tr', [_c('th', [_vm._v("승인번호")]), _c('td', [_c('span', {
    attrs: {
      "id": "authno",
      "name": "authno"
    }
  })])]), _c('tr', [_c('th', [_vm._v("주문번호")]), _c('td', [_c('span', {
    attrs: {
      "id": "ordno",
      "name": "ordno"
    }
  })])]), _c('tr', [_c('th', [_vm._v("금액")]), _c('td', [_c('span', {
    attrs: {
      "id": "amt",
      "name": "amt"
    }
  })])]), _c('tr', [_c('th', [_vm._v("메세지1")]), _c('td', [_c('span', {
    attrs: {
      "id": "msg1",
      "name": "msg1"
    }
  })])]), _c('tr', [_c('th', [_vm._v("메세지2")]), _c('td', [_c('span', {
    attrs: {
      "id": "msg2",
      "name": "msg2"
    }
  })])])])]), _c('div', {
    staticClass: "btnDiv"
  }, [_c('button', {
    staticClass: "viewButton",
    staticStyle: {
      "margin-left": "4px"
    },
    on: {
      "#onClick": function ($event) {
        return _vm.ksentOpen();
      }
    }
  }, [_vm._v("전표보기")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }